<template>
  <div class="">
    <br>
    <!-- {{ subscription }} -->
    <form @submit.prevent="submitReservation">
      <div class="row">
        <div class="col-3 form-group">
            <label>Nombre d'heures (Abonnement)</label>
            <input type="text" :disabled="true" class="form-control" :value="subscription.hours" />
        </div>

        <div class="col-3 form-group">
            <label>Heures restantes</label>
            <input type="text" :disabled="true" class="form-control" :value="subscription.soldHoursRest" />
        </div>

        <div class="col-3 form-group">
            <label>Nombre d'heures</label>
            <input type="text" class="form-control" v-model="reservation.hours" />
        </div>

        <div class="col-3 form-group">
            <label for="start-date">Date de début</label>
            <input type="date" id="start-date" class="form-control" v-model="reservation.startDate" @change="calculateEndDate" />
        </div>

        <div class="col-3 form-group">
            <label for="end-date">Date de fin</label>
            <input type="date" id="end-date" class="form-control" v-model="reservation.endDate" disabled />
        </div>

        <div class="col-3 form-group">
            <label for="day-of-week">Jour</label>
            <select id="day-of-week" class="form-control" v-model="reservation.dayOfWeek">
                <option value="">Sélectionner un jour</option>
                <option value="Monday">Lundi</option>
                <option value="Tuesday">Mardi</option>
                <option value="Wednesday">Mercredi</option>
                <option value="Thursday">Jeudi</option>
                <option value="Friday">Vendredi</option>
                <option value="Saturday">Samedi</option>
                <option value="Sunday">Dimanche</option>
            </select>
        </div>

        <div class="col-3 form-group">
            <label for="day-of-week"> Terrain </label>
            <select id="day-of-week" class="form-control" v-model="reservation.terrain_reference">
                <option value="">Sélectionner un le terrain</option>
                
                <option value="Saturday"> T-1 </option>
                <option value="Sunday"> T-2 </option>
            </select>
        </div> 

        <div class="col-3 form-group">
            <label > Start Time </label>
            <input type="text" :disabled="true" class="form-control" :value="reservation.startTime" />
        </div>

        <div class="col-3 form-group">
            <label > end Time </label>
            <input type="text" :disabled="true" class="form-control" :value="reservation.endTime" />
        </div>

      </div>
      <div class="form-group">
        <label for="start-time">Heur dubet </label>
        <div class="row overflow-x-auto flex-wrap">
          <div v-for="hour in timeSlots" :key="hour" class="col-1 p-2">
            <button
              type="button"
              class="w-100"
              :class="['btn', 'btn-outline-primary', { active: hour === startTime }]" 
              :disabled="isReserved(startDate, hour) || isReserved(endDate, hour)?true:false"
              @click="selectStartTime(hour)">
              {{ hour }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="filteredEndTimeSlots.length > 0" class="form-group">
        <label for="end-time">End Time</label>
        <div class="row d-flex overflow-x-auto flex-nowrap ">
          <div v-for="hour in filteredEndTimeSlots"  :key="hour" class="col-1 p-2">
            <button
              type="button"
              class="w-100"
              :class="['btn', 'btn-outline-primary', { active: hour === reservation.endTime }]" 
              :disabled="isReserved(reservation.date, hour)?true:false"
              @click="selectEndTime(hour)">
              {{ hour }}
            </button>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary mt-3">Reserver</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'; // Import moment.js if not already installed: npm install moment

export default {
  data() {
    return {
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      dayOfWeek: '',
      reservation: {
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        dayOfWeek: '',
        hours: '',
        subscription_reference: ''
      },
      timeSlots: [],
      endTimeSlots: [],
      terrainCount: 2,
      availableDates: [],
      filteredEndTimeSlots: [],
    };
  },
  async mounted() {
    if (!this.$route.query.fieldReference) {
      await this.$router.push({ name: 'customers-index' });
    }
    await this.$store.dispatch("reservation/getAll");
    await this.$store.dispatch("subscription/show", this.$route.query.subscription);
    this.generateTimeSlots();
  },
  methods: {
    generateTimeSlots() {
      const timeSlots = [
        '08:00', '09:10', '10:20', '11:30', '12:40',
        '13:50', '15:00', '16:10', '17:20', '18:30',
        '19:40', '20:50', '22:00', '23:10'
      ];
      this.timeSlots = timeSlots;
      this.endTimeSlots = timeSlots;
    },
    calculateEndDate() {
      if (this.reservation.startDate) {
        const startDate = moment(this.reservation.startDate);

        // Add 2 months and 15 days to the start date
        const endDate = startDate.add(2, 'months').add(6, 'days');

        // Set the formatted end date
        this.reservation.endDate = endDate.format('YYYY-MM-DD');
      }
    },
    selectStartTime(time) {
      this.reservation.startTime = time;
      this.updateFilteredEndTimeSlots();
    },

    updateFilteredEndTimeSlots() {
      const timeSlots = [
        '08:00', '09:10', '10:20', '11:30', '12:40',
        '13:50', '15:00', '16:10', '17:20', '18:30',
        '19:40', '20:50', '22:00', '23:10', '00:10'
      ];
      
      const startTime = this.reservation.startTime;
      const startIndex = timeSlots.indexOf(startTime);
      this.filteredEndTimeSlots = [];

      if (startIndex !== -1) {
        // Function to add specified hours and minutes to a time string
        const addTime = (time, hoursToAdd, minutesToAdd) => {
          const [hours, minutes] = time.split(':').map(Number);
          let newHours = hours + hoursToAdd;
          let newMinutes = minutes + minutesToAdd;
          
          if (newMinutes >= 60) {
            newHours++;
            newMinutes -= 60;
          }
          
          if (newHours >= 24) {
            newHours -= 24;
          }
          
          return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
        };

        // Add the next two time slots
        let firstSlot = addTime(startTime, 1, 0);  // Add 1 hour
        this.filteredEndTimeSlots.push(firstSlot);
        
        let secondSlot = addTime(startTime, 2, 0);  // Add 2 hours and 10 minutes
        this.filteredEndTimeSlots.push(secondSlot);
      }

      // Reset the end time of the reservation
      this.reservation.endTime = '';
    },
    selectEndTime(time) {
      this.reservation.endTime = time;
    },
    submitReservation() {
      this.$store.dispatch("reservation/storeProfessional", {
        hours: this.reservation.hours,
        startDate: this.reservation.startDate,
        endDate: this.reservation.endDate,
        dayOfWeek: this.reservation.dayOfWeek,
        startTime: this.reservation.startTime,
        endTime: this.reservation.endTime,
        field_reference: this.$route.query.fieldReference,
        subscription_reference: this.$route.query.subscription
      });
    },
    isReserved(date, hour) {
      if (date && hour) {
        const hourInt = parseInt(hour.split(':')[0], 10);
        const reservationsInDateAndHour = this.reservations.filter(reservation => {
          const reservationDate = reservation.date;
          const reservationStartHour = parseInt(reservation.startTime.split(':')[0], 10);
          const reservationEndHour = parseInt(reservation.endTime.split(':')[0], 10);
          return reservationDate === date && hourInt >= reservationStartHour && hourInt < reservationEndHour;
        });
        return reservationsInDateAndHour.length >= this.terrainCount;
      }
      return null;
    }
  },
  computed: {
    ...mapGetters('reservation', {
      reservations: 'getReservations',
    }),
    ...mapGetters("subscription", {
      subscription: "getSubscription",
    }),
  }
};
</script>


<style scoped>

</style>